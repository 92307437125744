<template>
  <div class="suBox">
    <div class="leftMenu">
      <div
        v-for="(item, index) in subMenu"
        :key="index"
        class="menuItem"
        :class="[activeRouter === item.path ? 'isactive' : '']"
        @click="$router.push(`${item.path}`)"
      >
        {{ item.meta.name }}
      </div>
    </div>
    <div class="RightBox">
      <div class="routerBox">
        <suspense>
          <router-view style="width: 100%; min-height: calc(100% - 27px)"></router-view>
        </suspense>
        <div class="WaterMark" v-if="!route.meta.noLogo">
          <img src="@/assets/images/WaterMark.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { watch, ref, nextTick } from 'vue'
import _ from 'lodash'
export default {
  setup() {
    const route = useRoute()
    let subMenu = ref()
    let activeRouter = ref()
    watch(
      route,
      (val) => {
        nextTick(() => {
          if (val.matched.length >= 3) {
            subMenu.value = val.matched[1].children
            activeRouter.value = val.matched[2].path
          }
        })
      },
      {
        deep: true,
        immediate: true
      }
    )
    return {
      subMenu,
      activeRouter,
      route
    }
  }
}
</script>

<style lang="less" scoped>
.suBox {
  width: 100%;
  height: 100%;
  display: flex;
  .leftMenu {
    width: 123px;
    min-height: 100%;
    border-right: 1px solid #e8e8e8;
    flex-shrink: 0;
    .menuItem {
      width: 100%;
      font-size: 14px;
      padding-left: 20px;
      margin: 20px 0 30px 0;
      cursor: pointer;
      color: #333;
    }
    .isactive {
      color: #1467ff !important;
      position: relative;
      &::before {
        content: '';
        display: block;
        width: 3px;
        height: 16px;
        background-color: #1467ff;
        position: absolute;
        top: 50%;
        left: 14px;
        transform: translateY(-50%);
      }
    }
  }
  .RightBox {
    flex: 1;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: auto;
    .routerBox {
      min-width: 1200px;
      padding: 20px;
    }
    .WaterMark {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
